<template>
  <Top></Top>
  <h3>碳循環計算規則</h3>
  <div class="uk-width-1-1" v-if="calorieGoal === 0 || carbsCyclingDays !== 7">
    <div class="uk-margin-small-bottom">
      <span class="uk-margin-small-right" uk-icon="icon: info"></span>
      <b class="uk-text-small"
        >您尚未設定碳循環週期，建議您先行設定即可看到完整說明。</b
      >
    </div>
    <button class="uk-button uk-button-secondary" @click="goCarbsCycling">
      點我前往設定
    </button>
  </div>
  <div class="uk-grid-divider uk-margin-large-top" uk-grid>
    <div class="uk-width-expand uk-margin-left uk-margin-right uk-margin-remove-right@m">
      <h4 class="uk-heading-line uk-heading-bullet uk-text-left">
        <span>STEP.1 計算每日營養素</span>
      </h4>
      <div class="uk-text-left">
        <p>
          <span class="uk-label uk-label-success">碳水</span> (目標熱量
          {{ calorieGoal }} - 蛋白質熱量 - 脂肪熱量) / {{ carbsCalorie }}
        </p>
        <p>
          <span class="uk-label uk-label-warn">蛋白質</span> 設定為體重
          {{ weight }} x {{ porteinMultiple }} 倍 = {{ porteinGoal }} 克
        </p>
        <p>
          <span class="uk-label uk-label-danger">脂肪</span> 體重 {{ weight }} x
          {{ fatPercent }} = {{ fatGoal }} 克
        </p>
      </div>
      <table class="uk-table uk-table-striped uk-table-middle">
        <thead>
          <tr>
            <th></th>
            <th>每日<br />克數</th>
            <th>每日<br />熱量</th>
            <th>每週<br />總克數</th>
            <th>每週<br />總熱量</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>碳水</td>
            <td>{{ carbsGoal }}</td>
            <td>{{ carbsGoal * carbsCalorie }}</td>
            <td>{{ carbsGoal * 7 }}</td>
            <td>{{ carbsGoal * 7 * carbsCalorie }}</td>
          </tr>
          <tr>
            <td>蛋白質</td>
            <td>{{ porteinGoal }}</td>
            <td>{{ porteinGoal * porteinCalorie }}</td>
            <td>{{ porteinGoal * 7 }}</td>
            <td>{{ porteinGoal * 7 * porteinCalorie }}</td>
          </tr>
          <tr>
            <td>脂肪</td>
            <td>{{ fatGoal }}</td>
            <td>{{ fatGoal * fatCalorie }}</td>
            <td>{{ fatGoal * 7 }}</td>
            <td>{{ fatGoal * 7 * fatCalorie }}</td>
          </tr>
          <tr>
            <td>加總</td>
            <td>{{ carbsGoal + porteinGoal + fatGoal }}</td>
            <td>{{ calorieGoal.toFixed(0) }}</td>
            <td>{{ (carbsGoal + porteinGoal + fatGoal) * 7 }}</td>
            <td>
              {{
                carbsGoal * carbsCalorie * 7 +
                porteinGoal * porteinCalorie * 7 +
                fatGoal * fatCalorie * 7
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="uk-width-expand@m uk-margin-left uk-margin-right uk-margin-remove-left@m uk-margin-remove-right@m">
      <h4 class="uk-heading-line uk-heading-bullet uk-text-left">
        <span>STEP.2 每週營養素比例</span>
      </h4>
      <div class="uk-text-left">
        <p>
          以高碳日為例，攝取本週 {{ carbsCyclingPercent[0].carbs * 100 }}%
          的碳水及 {{ carbsCyclingPercent[0].fat * 100 }}% 的脂肪
        </p>
        <p>
          <span class="uk-label uk-label-success">碳水</span>
          {{ carbsGoal * 7 }} 克 x {{ carbsCyclingPercent[0].carbs * 100 }}% /
          {{ highCarbsDay.length }} 天 =
          {{ CalcCarbsCyclingNutrition[0].carbs.toFixed(0) }} 克
        </p>
        <p>
          <span class="uk-label uk-label-warn">蛋白質</span> 設定為體重
          {{ weight }} x {{ porteinMultiple }} 倍 = {{ porteinGoal }} 克
        </p>
        <p>
          <span class="uk-label uk-label-danger">脂肪</span>
          {{ fatGoal * 7 }} 克 x {{ carbsCyclingPercent[0].fat * 100 }}% /
          {{ highCarbsDay.length }} 天 =
          {{ CalcCarbsCyclingNutrition[0].fat.toFixed(0) }} 克
        </p>
      </div>
      <table class="uk-table uk-table-striped uk-text-center">
        <thead>
          <tr>
            <th>(每週)</th>
            <th>高碳日 {{ highCarbsDay.length }} 天</th>
            <th>中碳日 {{ mediumCarbsDay.length }} 天</th>
            <th>低碳日 {{ lowCarbsDay.length }} 天</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>碳水</td>
            <td>{{ carbsCyclingPercent[0].carbs * 100 }}%</td>
            <td>{{ carbsCyclingPercent[1].carbs * 100 }}%</td>
            <td>{{ carbsCyclingPercent[2].carbs * 100 }}%</td>
          </tr>
          <tr>
            <td>蛋白質</td>
            <td colspan="3">固定為體重 x {{ porteinMultiple }} 倍</td>
          </tr>
          <tr>
            <td>脂肪</td>
            <td>{{ carbsCyclingPercent[0].fat * 100 }}%</td>
            <td>{{ carbsCyclingPercent[1].fat * 100 }}%</td>
            <td>{{ carbsCyclingPercent[2].fat * 100 }}%</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      class="
        uk-width-expand uk-margin-left uk-margin-right uk-margin-remove-left@m
      "
    >
      <h4 class="uk-heading-line uk-heading-bullet uk-text-left">
        <span>STEP.3 高/中/低碳日營養素攝取量</span>
      </h4>
      <div class="uk-text-left">
        <p>
          雖然每天攝取的熱量不同，但整週的總熱量為
          {{ weeklyCalorie }}
          卡，與本週 TDEE 的總熱量
          <template v-if="goal === 0">持平，能維持體態。</template>
          <template v-else>
            {{ TDEE * 7 }}
            <template v-if="goal > 0">增加</template>
            <template v-if="goal < 0">減少</template>
            {{ Math.abs(weeklyCalorie - TDEE * 7) }} 卡，持之以恆能達到 「<template
              v-if="goal > 0"
              >增重</template
            >
            <template v-if="goal < 0">減重</template>」 的效果。
          </template>
        </p>
        <p class="uk-margin-small-bottom">
          <span class="uk-margin-small-right" uk-icon="icon: info"></span>
          <b class="uk-text-small">建議要每週測量體重，調整您的目標熱量喔！</b>
        </p>
      </div>
      <table class="uk-table uk-table-striped">
        <thead>
          <tr>
            <th>(克)</th>
            <th>高碳日</th>
            <th>中碳日</th>
            <th>低碳日</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>碳水</td>
            <td>{{ CalcCarbsCyclingNutrition[0].carbs.toFixed(0) }}</td>
            <td>{{ CalcCarbsCyclingNutrition[1].carbs.toFixed(0) }}</td>
            <td>{{ CalcCarbsCyclingNutrition[2].carbs.toFixed(0) }}</td>
          </tr>
          <tr>
            <td>蛋白質</td>
            <td>{{ CalcCarbsCyclingNutrition[0].portein.toFixed(0) }}</td>
            <td>{{ CalcCarbsCyclingNutrition[1].portein.toFixed(0) }}</td>
            <td>{{ CalcCarbsCyclingNutrition[2].portein.toFixed(0) }}</td>
          </tr>
          <tr>
            <td>脂肪</td>
            <td>{{ CalcCarbsCyclingNutrition[0].fat.toFixed(0) }}</td>
            <td>{{ CalcCarbsCyclingNutrition[1].fat.toFixed(0) }}</td>
            <td>{{ CalcCarbsCyclingNutrition[2].fat.toFixed(0) }}</td>
          </tr>
        </tbody>
      </table>
      <table class="uk-table uk-table-striped">
        <thead>
          <tr>
            <th>(熱量)</th>
            <th>高碳日</th>
            <th>中碳日</th>
            <th>低碳日</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>碳水</td>
            <td>
              {{ CalcCarbsCyclingNutrition[0].carbs.toFixed(0) * carbsCalorie }}
            </td>
            <td>
              {{ CalcCarbsCyclingNutrition[1].carbs.toFixed(0) * carbsCalorie }}
            </td>
            <td>
              {{ CalcCarbsCyclingNutrition[2].carbs.toFixed(0) * carbsCalorie }}
            </td>
          </tr>
          <tr>
            <td>蛋白質</td>
            <td>
              {{
                CalcCarbsCyclingNutrition[0].portein.toFixed(0) * porteinCalorie
              }}
            </td>
            <td>
              {{
                CalcCarbsCyclingNutrition[1].portein.toFixed(0) * porteinCalorie
              }}
            </td>
            <td>
              {{
                CalcCarbsCyclingNutrition[2].portein.toFixed(0) * porteinCalorie
              }}
            </td>
          </tr>
          <tr>
            <td>脂肪</td>
            <td>
              {{ CalcCarbsCyclingNutrition[0].fat.toFixed(0) * fatCalorie }}
            </td>
            <td>
              {{ CalcCarbsCyclingNutrition[1].fat.toFixed(0) * fatCalorie }}
            </td>
            <td>
              {{ CalcCarbsCyclingNutrition[2].fat.toFixed(0) * fatCalorie }}
            </td>
          </tr>
          <tr>
            <td>加總</td>
            <td>
              {{
                CalcCarbsCyclingNutrition[0].carbs.toFixed(0) * carbsCalorie +
                CalcCarbsCyclingNutrition[0].portein.toFixed(0) *
                  porteinCalorie +
                CalcCarbsCyclingNutrition[0].fat.toFixed(0) * fatCalorie
              }}
            </td>
            <td>
              {{
                CalcCarbsCyclingNutrition[1].carbs.toFixed(0) * carbsCalorie +
                CalcCarbsCyclingNutrition[1].portein.toFixed(0) *
                  porteinCalorie +
                CalcCarbsCyclingNutrition[1].fat.toFixed(0) * fatCalorie
              }}
            </td>
            <td>
              {{
                CalcCarbsCyclingNutrition[2].carbs.toFixed(0) * carbsCalorie +
                CalcCarbsCyclingNutrition[2].portein.toFixed(0) *
                  porteinCalorie +
                CalcCarbsCyclingNutrition[2].fat.toFixed(0) * fatCalorie
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
Ç
<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters, mapState } from "vuex";
import * as T from "@/models/Nutrition";
import calc, {
  carbsCalorie,
  porteinCalorie,
  fatCalorie,
  fatPercent,
  carbsCyclingPercent,
} from "../utils/calc";

export default defineComponent({
  data() {
    return {
      carbsCalorie,
      porteinCalorie,
      fatCalorie,
      fatPercent,
      carbsCyclingPercent,
    };
  },
  computed: {
    CalcCarbsCyclingNutrition(): Array<T.Nutrition | null> {
      const obj = calc.CalcCarbsCyclingNutrition(
        this.highCarbsDay.length,
        this.mediumCarbsDay.length,
        this.lowCarbsDay.length,
        this.carbsGoal,
        this.porteinGoal,
        this.fatGoal
      );
      return obj;
    },
    weeklyCalorie(): number {
      return (
        this.carbsGoal * carbsCalorie * 7 +
        this.porteinGoal * porteinCalorie * 7 +
        this.fatGoal * fatCalorie * 7
      );
    },
    porteinMultiple(): string {
      return this.$store.state.porteinMultiple.toFixed(2);
    },
    ...mapState([
      "weight",
      "highCarbsDay",
      "mediumCarbsDay",
      "lowCarbsDay",
      "goal",
    ]),
    ...mapGetters([
      "calorieGoal",
      "porteinGoal",
      "carbsGoal",
      "fatGoal",
      "carbsCyclingDays",
      "TDEE",
    ]),
  },
  methods: {
    goCarbsCycling() {
      this.$router.push("/carbs-cycling");
    },
  },
});
</script>

<style scoped>
.uk-table th {
  text-align: center;
}
</style>
