
import { defineComponent } from "vue";
import { mapGetters, mapState } from "vuex";
import * as T from "@/models/Nutrition";
import calc, {
  carbsCalorie,
  porteinCalorie,
  fatCalorie,
  fatPercent,
  carbsCyclingPercent,
} from "../utils/calc";

export default defineComponent({
  data() {
    return {
      carbsCalorie,
      porteinCalorie,
      fatCalorie,
      fatPercent,
      carbsCyclingPercent,
    };
  },
  computed: {
    CalcCarbsCyclingNutrition(): Array<T.Nutrition | null> {
      const obj = calc.CalcCarbsCyclingNutrition(
        this.highCarbsDay.length,
        this.mediumCarbsDay.length,
        this.lowCarbsDay.length,
        this.carbsGoal,
        this.porteinGoal,
        this.fatGoal
      );
      return obj;
    },
    weeklyCalorie(): number {
      return (
        this.carbsGoal * carbsCalorie * 7 +
        this.porteinGoal * porteinCalorie * 7 +
        this.fatGoal * fatCalorie * 7
      );
    },
    porteinMultiple(): string {
      return this.$store.state.porteinMultiple.toFixed(2);
    },
    ...mapState([
      "weight",
      "highCarbsDay",
      "mediumCarbsDay",
      "lowCarbsDay",
      "goal",
    ]),
    ...mapGetters([
      "calorieGoal",
      "porteinGoal",
      "carbsGoal",
      "fatGoal",
      "carbsCyclingDays",
      "TDEE",
    ]),
  },
  methods: {
    goCarbsCycling() {
      this.$router.push("/carbs-cycling");
    },
  },
});
